import React from "react";
import { auth } from "../scripts/firebase";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import "../styles/DynamicTables.css";

const Login = () => {
  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      // Trigger Google Sign-In popup
      const result = await signInWithPopup(auth, provider);
      console.log("User signed in:", result.user);
    } catch (error) {
      console.error("Error during sign-in:", error.message);
    }
  };

  return (
    <div className="login">
      <div className="loginBox">
        <h1>Portfolio Manager</h1>
        <br></br>
        <br></br>
        <button onClick={handleGoogleLogin} className="loginButton">
          Login with Google
        </button>
        <br></br>
        <br></br>
      </div>
    </div>
  );
};


export default Login;

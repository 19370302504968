import axios from "axios"; // Import Axios as a default export

/**
 * Fetch the latest price for a given trading pair using your Lambda endpoint.
 *
 * @param {string} pair - The trading pair (e.g., "BTCUSD").
 * @returns {Promise<number|null>} - The price as a number or null if there's an error.
 */
async function fetchKrakenPrice(pair) {
  // Your Lambda endpoint
  const url = `https://oe04dk0k01.execute-api.eu-central-1.amazonaws.com/prod/krakenApi`;

  try {
    // Make a GET request to your Lambda endpoint with the query parameter
    const response = await axios.get(url, {
      params: {
        pair, // This sets ?pair=BTCUSD in the request
      },
      headers: {
        Accept: "application/json",
      },
    });

    // Check if the response is valid
    if (response.status !== 200 || !response.data) {
      throw new Error("Invalid response from Lambda");
    }

    // Extract the price from the response
    const { price } = response.data;
    return parseFloat(price); // Return the price as a number
  } catch (error) {
    console.error(`Failed to fetch price from Lambda: ${error.message}`);
    return null; // Return null if there's an error
  }
}

export default fetchKrakenPrice;

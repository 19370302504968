import { db } from "./firebase"; // Import your Firebase configuration
import { collection, addDoc } from "firebase/firestore";

export const saveFormData = async (formName, formData) => {
  try {
    const docRef = await addDoc(collection(db, formName), formData);
    console.log("Document written with ID: ", docRef.id);
  } catch (error) {
    console.error("Error adding document: ", error);
    throw error; // Re-throw the error to handle it in the form
  }
};

import React, { useEffect, useState } from "react";
import "../styles/DynamicTables.css";
import { db } from "../scripts/firebase";
import { collection, onSnapshot, doc, updateDoc, getDocs } from "firebase/firestore";
import { compoundPool, getEthPriceInDollar, getPositionDataDetailed, getPositionDataDetailedRaw, getTokenPriceInEth } from "../scripts/uniswapAdapter"; // Import the getPosition function
import BarChart from "./BarChart";
import fetchKrakenPrice from "../scripts/krakenAdapter";
import { fetchUniswapPrice } from "../scripts/uniswapAdapter";
import fetchCoinMarketCapQuotes from "../scripts/cmcAdapter";
import { fetchPremiumIndex } from "../scripts/binanceAdapter";

const DynamicTables = ({ activeTab, setActiveTab, tabs }) => {
  const [data, setData] = useState([]);
  const [pools, setPools] = useState([]);
  const [investments, setInvestments] = useState([]);
  const [symbols, setSymbols] = useState([]);
  const [totals, setTotals] = useState([]);

  const authorizedEmails = [
    "cedric.hirschi@gmail.com", // Replace with authorized emails
    "mauro.r.miotti@gmail.com",
    "namaracata@gmail.com",
  ];

  useEffect(() => {
    if (activeTab) {
      if (activeTab === "Totals" || activeTab === "Dashboard") {
        // Fetch Investments and Pools data
        const unsubscribeInvestments = onSnapshot(collection(db, "Investments"), (snapshot) => {
          const investmentsData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          const unsubscribePools = onSnapshot(collection(db, "Pools"), (snapshot) => {
            const poolsData = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

            const unsubscribeSymbols = onSnapshot(collection(db, "Symbols"), (snapshot) => {
              const symbolsData = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }));

              // Combine Investments and Pools
              const combinedData = combineInvestmentsPoolsAndSymbols(investmentsData, poolsData, symbolsData);
              const combinedInvestments = combineInvestmentsAndSymbols(investmentsData, symbolsData);
              setTotals(combinedData);
              setInvestments(combinedInvestments);
              setPools(poolsData);
              setSymbols(symbolsData);
            });
            return () => unsubscribeSymbols();
          });
          return () => unsubscribePools();
        });
        return () => unsubscribeInvestments();
      } else if (activeTab === "Investments") {
        // Fetch Investments and Pools data
        const unsubscribeInvestments = onSnapshot(collection(db, "Investments"), (snapshot) => {
          const investmentsData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          const unsubscribeSymbols = onSnapshot(collection(db, "Symbols"), (snapshot) => {
            const symbolsData = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

            // Combine Investments and Pools
            const combinedData = combineInvestmentsAndSymbols(investmentsData, symbolsData);
            setInvestments(combinedData);
          });
          return () => unsubscribeSymbols();
        });
        return () => unsubscribeInvestments();
      } else {
        // Default behavior for other tabs
        const unsubscribe = onSnapshot(collection(db, activeTab), (snapshot) => {
          const tableData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setData(tableData);
        });

        return () => unsubscribe();
      }
    }
  }, [activeTab]);

  const combineInvestmentsPoolsAndSymbols = (investments, pools, symbols) => {
    // Clone investments to avoid mutating original data
    const combinedData = investments.map((inv) => ({
      ...inv,
      quantity: parseFloat(inv.quantity || inv.Quantity || 0), // Handle both 'quantity' and 'Quantity' cases
    }));

    //console.log("Investments: ", combinedData);
    //console.log("Pools", pools);
    //console.log("Symbols: ", symbols);

    // Iterate through the pools
    pools.forEach((pool) => {
      // Extract token0 and token1 data from the pool
      const { token0, token1, transferSystem, token0Liquidity = 0, token1Liquidity = 0 } = pool;

      // Match and update investments for token0
      const token0Investment = combinedData.find(
        (investment) => investment.symbol === token0 && investment.transferSystem === transferSystem
      );
      if (token0Investment) {
        token0Investment.quantity += token0Liquidity;
      } else {
        // If no match is found, optionally add a new entry (if needed)
        combinedData.push({
          symbol: token0,
          transferSystem: transferSystem,
          quantity: token0Liquidity,
          price: 0, // Set price to 0 if it doesn't exist
          value: 0, // Set value to 0 if it doesn't exist
        });
      }

      // Match and update investments for token1
      const token1Investment = combinedData.find(
        (investment) => investment.symbol === token1 && investment.transferSystem === transferSystem
      );
      if (token1Investment) {
        token1Investment.quantity += token1Liquidity;
      } else {
        // If no match is found, optionally add a new entry (if needed)
        combinedData.push({
          symbol: token1,
          transferSystem: transferSystem,
          quantity: token1Liquidity,
          price: 0, // Set price to 0 if it doesn't exist
          value: 0, // Set value to 0 if it doesn't exist
        });
      }
    });

    // Add dollarValue to each investment
    combinedData.forEach((data) => {
      const matchingSymbol = symbols.find(
        (symbol) => symbol.symbol === data.symbol && symbol.transferSystem === data.transferSystem
      );
      if (matchingSymbol) {
        data.dollarValue = data.quantity * (matchingSymbol.priceInDollar || 0); // Use priceInDollar if available
      } else {
        data.dollarValue = 0; // Default to 0 if no matching symbol is found
      }
    });

    //console.log("Combined Data with Dollar Values:", combinedData);
    return combinedData;
  };
  const combineInvestmentsAndSymbols = (investments, symbols) => {
    // Clone investments to avoid mutating original data
    const updatedInvestments = investments.map((investment) => {
      // Find the matching symbol in the symbols dataset
      const matchingSymbol = symbols.find(
        (symbol) => symbol.symbol === investment.symbol && symbol.transferSystem === investment.transferSystem
      );

      // Calculate the dollarValue if a matching symbol is found
      const dollarValue =
        matchingSymbol && matchingSymbol.priceInDollar ? investment.quantity * matchingSymbol.priceInDollar : 0; // Default to 0 if no matching symbol or priceInDollar is found

      // Return the updated investment with the new dollarValue property
      return {
        ...investment,
        dollarValue, // Add the calculated dollarValue to the investment object
      };
    });

    // Return the updated investments dataset
    return updatedInvestments;
  };

  const renderExtras = () => {
    const totalValue = totals.reduce((sum, item) => sum + (item.dollarValue || 0), 0).toFixed(2);

    return (
      <div className="table-container" key="Dashboard">
        <button
          className="tab-button"
          onClick={async () => {
            const result = await compoundPool();
            console.log(result);
          }}
        >
          Compound
        </button>
      </div>
    );
  };
  const handleRefresh = async (transferSystem, positionId, documentId) => {
    try {
      // Fetch position data
      const positionData = await getPositionDataDetailed(transferSystem, positionId);

      // Update the document in Firestore
      const docRef = doc(db, "Pools", documentId);
      await updateDoc(docRef, {
        token0Liquidity: positionData.liquidityAmountToken0,
        token1Liquidity: positionData.liquidityAmountToken1,
        token0UncollectedFees: positionData.uncollectedFeeToken0,
        token1UncollectedFees: positionData.uncollectedFeeToken1,
      });
    } catch (error) {
      console.error("Error refreshing position data:", error);
    }
  };
  const handleCompound = async (transferSystem, positionId, documentId) => {
    try {
      const positionData = await getPositionDataDetailedRaw(transferSystem, positionId);
      const result = await compoundPool(positionId,transferSystem,positionData.uncollectedFeeToken0,positionData.uncollectedFeeToken1);
      console.log(result);
    } catch (error) {
      console.error("Error refreshing position data:", error);
    }
  };
  const renderDashboard = () => {
    const totalValue = totals.reduce((sum, item) => sum + (item.dollarValue || 0), 0).toFixed(2);

    return (
      <div className="table-container" key="Dashboard">
        <br></br>
        <span className="dashboard-bullet">Total Value: </span>
        <span className="dashboard-bullet-number">{`${totalValue} USDC`}</span>
        <br></br>
        <br></br>
        <h1 className="form-title">Investments</h1>
        <BarChart inputData={investments} dataType={"investments"} />
        <br></br>
        <h1 className="form-title">Pools</h1>
        <BarChart inputData={pools} dataType={"pools"} />
        <br></br>
        <h1 className="form-title">Total</h1>
        <BarChart inputData={totals} dataType={"positions"} />
      </div>
    );
  };
  const renderTable = (tab) => {
    // Log the `data` being passed for rendering
    let mappingData;
    if (tab.name === "Pools") {
      mappingData = pools;
    } else if (tab.name === "Investments") {
      mappingData = investments;
    } else if (tab.name === "Totals") {
      mappingData = totals;
    } else {
      mappingData = data;
    }
    //console.log("Render tab: ", tab);
    //console.log(`Current data:`, mappingData);

    return (
      <div className="table-container" key={tab.name}>
        <div className="table-header">
          <h1 className="form-title">{tab.title}</h1>
          {tab.name === "Symbols" && (
            <button className="refresh-all-button" onClick={() => handleRefreshAll()}>
              Refresh Prices
            </button>
          )}
        </div>
        <table className="data-table">
          <thead>
            <tr>
              {tab.columns.map((col, index) => (
                <th key={index}>{col}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {mappingData.length > 0 ? (
              mappingData.map((row, index) => {
                return (
                  <tr key={index}>
                    {tab.name === "Holders" && (
                      <>
                        <td>{row.firstName || "N/A"}</td>
                        <td>{row.lastName || "N/A"}</td>
                        <td>{row.share !== undefined ? row.share : 0}</td>
                      </>
                    )}
                    {tab.name === "Pools" && (
                      <>
                        <td>
                          {`${row.token0}/${row.token1} ${row.fee}% (${row.transferSystem}) (${
                            row.positionId || "N/A"
                          })`}
                        </td>
                        <td>{row.token0Liquidity || 0}</td>
                        <td>{row.token1Liquidity || 0}</td>
                        <td>{row.token0UncollectedFees || 0}</td>
                        <td>{row.token1UncollectedFees || 0}</td>
                        <td>
                          <button
                            className="refresh-button"
                            onClick={() => handleRefresh(row.transferSystem, row.positionId, row.id)}
                          >
                            Refresh
                          </button>
                        </td>
                        <td>
                          <button
                            className="refresh-button"
                            onClick={() => handleCompound(row.transferSystem, row.positionId, row.id)}
                          >
                            Compound
                          </button>
                        </td>
                      </>
                    )}
                    {tab.name === "PoolInflows" && (
                      <>
                        <td>{row.positionId || "N/A"}</td>
                        <td>{row.token0 || "N/A"}</td>
                        <td>{row.token1 || "N/A"}</td>
                        <td>{row.token0Added || 0}</td>
                        <td>{row.token1Added || 0}</td>
                        <td>{new Date(row.datetime).toLocaleString() || "N/A"}</td>
                      </>
                    )}
                    {tab.name === "PoolOutflows" && (
                      <>
                        <td>{row.positionId || "N/A"}</td>
                        <td>{row.token0 || "N/A"}</td>
                        <td>{row.token1 || "N/A"}</td>
                        <td>{row.token0Removed || 0}</td>
                        <td>{row.token1Removed || 0}</td>
                        <td>{new Date(row.datetime).toLocaleString() || "N/A"}</td>
                      </>
                    )}
                    {tab.name === "TransferSystems" && (
                      <>
                        <td>{row.name || "N/A"}</td>
                        <td>{row.symbol || "N/A"}</td>
                      </>
                    )}
                    {tab.name === "Symbols" && (
                      <>
                        <td>{row.symbol || "N/A"}</td>
                        <td>{row.name || "N/A"}</td>
                        <td>{row.transferSystem || "N/A"}</td>
                        <td>{row.priceSource || "N/A"}</td>
                        <td>{row.tokenAddress || "0x0"}</td>
                        <td>{row.priceInDollar || "N/A"}</td>
                      </>
                    )}
                    {tab.name === "Trades" && (
                      <>
                        <td>{row.inputCurrency || "N/A"}</td>
                        <td>{row.outputCurrency || "N/A"}</td>
                        <td>{row.inputQuantity || "N/A"}</td>
                        <td>{row.outputQuantity || "N/A"}</td>
                        <td>{new Date(row.datetime).toLocaleString() || "N/A"}</td>
                      </>
                    )}
                    {tab.name === "Investments" && (
                      <>
                        <td>{row.symbol || "N/A"}</td>
                        <td>{row.transferSystem || "N/A"}</td>
                        <td>{row.quantity || 0}</td>
                        <td>{row.dollarValue || 0}</td>
                      </>
                    )}
                    {tab.name === "InOutFlows" && (
                      <>
                        <td>{row.holder || "N/A"}</td>
                        <td>{row.direction || "N/A"}</td>
                        <td>{row.quantity || "N/A"}</td>
                        <td>{row.datetime || "N/A"}</td>
                      </>
                    )}
                    {tab.name === "Totals" && (
                      <>
                        <td>{row.symbol || "N/A"}</td>
                        <td>{row.transferSystem || "N/A"}</td>
                        <td>{row.quantity || 0}</td>
                        <td>{row.dollarValue || 0}</td>
                      </>
                    )}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={tab.columns.length}>No data available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  const handleRefreshAll = async () => {
    console.log("Refreshing all prices...");
    let priceOfOneEthInUSDC = null;

    try {
      // Fetch ETH/USDT price from Kraken
      priceOfOneEthInUSDC = await getEthPriceInDollar();
      //console.log(`Price of 1 WETH in USDC: ${priceOfOneEthInUSDC}`);
    } catch (error) {
      console.error("Failed to fetch ETH/USDT price:", error);
      return;
    }

    const priceUpdates = [];
    //console.log("Symbols:", symbols);

    for (const symbolData of symbols) {
      let updateData = {
        symbolToUpdate: symbolData,
        priceInDollar: 0,
        priceInEth: 0,
        update: false,
      };
      const { symbol, priceSource, tokenAddress, transferSystem } = symbolData;

      if (symbol === "USDT" || symbol === "USDC" || symbol === "USD" || symbol.includes("USD")) {
        updateData.priceInDollar = 1;
        updateData.update = true;
      } else if (symbol === "ETH" || symbol === "WETH") {
        // For ETH or WETH
        updateData.priceInDollar = priceOfOneEthInUSDC;
        updateData.update = true;
      } else {
        // For all other assets
        try {
          if (priceSource === "Uniswap") {
            const tokenPriceInEth = await getTokenPriceInEth(transferSystem, tokenAddress);
           
            updateData.priceInEth = tokenPriceInEth;
            updateData.priceInDollar = tokenPriceInEth * priceOfOneEthInUSDC;
            updateData.update = true;
          } else if (priceSource === "Kraken") {
            var krakenprice = await fetchKrakenPrice(`${symbol}USD`);
            updateData.priceInDollar = krakenprice;
            updateData.update = true;
          }
        } catch (error) {
          console.error(`Failed to fetch price for ${symbol}:`, error);
        }
      }

      priceUpdates.push(updateData);
    }

    console.log("Price Updates:", priceUpdates);

    // Update Symbols table in Firebase
    for (const update of priceUpdates) {
      if (update.update) {
        const { symbolToUpdate, priceInDollar } = update;
        try {
          const symbolDocRef = doc(db, "Symbols", symbolToUpdate.id);
          await updateDoc(symbolDocRef, {
            priceInDollar,
          });
          //console.log(`Updated ${symbolToUpdate.symbol} in Symbols collection.`);
        } catch (error) {
          console.error(`Failed to update ${symbolToUpdate.symbol}:`, error);
        }
      }
    }
  };

  return (
    <div className="dynamic-tables">
      <div className="tab-content">
        {activeTab === "Dashboard"
          ? renderDashboard()
          : activeTab === "Extras"
          ? renderExtras()
          : tabs.filter((tab) => tab.name === activeTab).map((tab) => renderTable(tab))}
      </div>
    </div>
  );
};

export default DynamicTables;

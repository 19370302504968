import React, { useState } from "react";
import "../styles/Forms.css";

const ClaimPoolFeeForm = () => {
  const [formData, setFormData] = useState({
    pair: "",
    token0Claimed: "",
    token1Claimed: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Submitted:", formData);
  };

  const pairs = [
    "ALB/ETH 0.5% (ID: 9879879)",
  ];

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        <h1 className="form-title">Claim Pool Fee</h1>
        <p className="form-description">
          Account for claimed pool fees.
        </p>

        {/* Pair */}
        <div className="form-field">
          <label className="form-label">
            Pair *
            <span className="form-hint">
              (Choose the pool which you removed liquidity from.)
            </span>
          </label>
          <div className="form-radio-group">
            {pairs.map((pair, index) => (
              <label className="form-radio" key={index}>
                <input
                  type="radio"
                  name="pair"
                  value={pair}
                  onChange={handleChange}
                  required
                />
                {pair}
              </label>
            ))}
          </div>
        </div>

        {/* Token 0 Claimed Quantity */}
        <div className="form-field">
          <label htmlFor="token0Claimed" className="form-label">
            Token 0 Claimed Quantity *
          </label>
          <input
            type="number"
            id="token0Claimed"
            name="token0Claimed"
            value={formData.token0Claimed}
            onChange={handleChange}
            placeholder="Enter the claimed quantity for Token 0"
            required
            className="form-input"
            step="0.01"
          />
        </div>

        {/* Token 1 Claimed Quantity */}
        <div className="form-field">
          <label htmlFor="token1Claimed" className="form-label">
            Token 1 Claimed Quantity *
          </label>
          <input
            type="number"
            id="token1Claimed"
            name="token1Claimed"
            value={formData.token1Claimed}
            onChange={handleChange}
            placeholder="Enter the claimed quantity for Token 1"
            required
            className="form-input"
            step="0.01"
          />
        </div>

        <button type="submit" className="form-button">
          Submit
        </button>
      </form>
    </div>
  );
};

export default ClaimPoolFeeForm;

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDJ7tN9qE4or1lgCVh8QlMhpUgF0wXgd3g",
    authDomain: "ccms-d0dc6.firebaseapp.com",
    projectId: "ccms-d0dc6",
    storageBucket: "ccms-d0dc6.firebasestorage.app",
    messagingSenderId: "272511232666",
    appId: "1:272511232666:web:91f8c3aa15dfc57b460d23"
  };

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

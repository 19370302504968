import React, { useState } from "react";
import "../styles/Forms.css";
import { saveFormData } from "../scripts/formHandler"; // Import the saveFormData function

const AddTransferSystemForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    symbol: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false); // For loading state
  const [message, setMessage] = useState(""); // For success/error message

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true); // Indicate form is being submitted
    setMessage(""); // Clear any previous messages

    try {
      // Save the form data to the "TransferSystems" collection
      await saveFormData("TransferSystems", formData);
      setMessage("Transfer system added successfully!");
      setFormData({ name: "", symbol: "" }); // Reset form fields
    } catch (error) {
      setMessage("Failed to add transfer system. Please try again.");
      console.error("Error saving transfer system data:", error);
    } finally {
      setIsSubmitting(false); // Stop the loading state
    }
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        <h1 className="form-title">Add Transfer System</h1>
        <p className="form-description">
          Add a new transfer system to the portfolio manager. Make sure to implement the respective scripts.
        </p>
        <div className="form-field">
          <label htmlFor="name" className="form-label">
            Name *
            <span className="form-hint">
              (Name of the transfer system, e.g., Bitcoin Mainnet, Ethereum Mainnet, Traditional Finance)
            </span>
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter the transfer system name"
            required
            className="form-input"
          />
        </div>
        <div className="form-field">
          <label htmlFor="symbol" className="form-label">
            Symbol *
            <span className="form-hint">
              (Symbol for the transfer system, e.g., btcMain, ethMain, tradFi)
            </span>
          </label>
          <input
            type="text"
            id="symbol"
            name="symbol"
            value={formData.symbol}
            onChange={handleChange}
            placeholder="Enter the symbol"
            required
            className="form-input"
          />
        </div>
        <button type="submit" className="form-button" disabled={isSubmitting}>
          {isSubmitting ? "Submitting..." : "Submit"}
        </button>
        {message && <p className="form-message">{message}</p>}
      </form>
    </div>
  );
};

export default AddTransferSystemForm;

import { NETWORK_CONFIG } from "./chainConfig";
import { NonfungiblePositionManager, Pool, Position, TickMath } from "@uniswap/v3-sdk";
import { Contract, JsonRpcProvider, parseUnits, ZeroAddress } from "ethers";
import { Token } from "@uniswap/sdk-core";
import JSBI from "jsbi";
import { formatUnits, BrowserProvider } from "ethers";

const TOKEN_MAP = {};
const initialisedProviders = {};
const AddressZero = "0x0000000000000000000000000000000000000000";

const Q96 = JSBI.exponentiate(JSBI.BigInt(2), JSBI.BigInt(96)); // 2^96
const Q192 = JSBI.exponentiate(Q96, JSBI.BigInt(2)); // 2^192

//Uniswap V3

const artifacts = {
  UniswapV3Factory: require("@uniswap/v3-core/artifacts/contracts/UniswapV3Factory.sol/UniswapV3Factory.json"),
  NonfungiblePositionManager: require("@uniswap/v3-periphery/artifacts/contracts/NonfungiblePositionManager.sol/NonfungiblePositionManager.json"),
  UniswapV3Pool: require("@uniswap/v3-core/artifacts/contracts/UniswapV3Pool.sol/UniswapV3Pool.json"),
  UniswapIQuoter: require("@uniswap/v3-periphery/artifacts/contracts/interfaces/IQuoterV2.sol/IQuoterV2.json"),
  SwapRouter: require("@uniswap/v3-periphery/artifacts/contracts/interfaces/ISwapRouter.sol/ISwapRouter.json"),
  ERC20: require("@openzeppelin/contracts/build/contracts/ERC20.json"),
};

function initProviders() {
  for (const networkName in NETWORK_CONFIG) {
    const network = NETWORK_CONFIG[networkName];
    initialisedProviders[networkName] = new JsonRpcProvider(network.rpcUrl);
  }
}
function getProvider(networkName) {
  const provider = initialisedProviders[networkName];
  if (!provider) {
    throw new Error(`Provider not found for:`, networkName);
  }
  return provider;
}

export async function getPositionDataDetailed(networkName, positionId) {
  const positionData = await getPosition(networkName, positionId);

  const poolAddress = await getPoolAddress(
    networkName,
    positionData.token0,
    positionData.token1,
    Number(positionData.fee)
  );

  console.log(poolAddress);
  const feeGrowthInside = await getFeeGrowthInside(
    poolAddress,
    positionData.tickLower,
    positionData.tickUpper,
    networkName
  );

  const liq = await calculatePositionDataOriginal(
    positionData.token0,
    positionData.token1,
    poolAddress,
    positionData,
    feeGrowthInside,
    networkName
  );
  return liq;
}
export async function getPositionDataDetailedRaw(networkName, positionId) {
  const positionData = await getPosition(networkName, positionId);

  const poolAddress = await getPoolAddress(
    networkName,
    positionData.token0,
    positionData.token1,
    Number(positionData.fee)
  );

  console.log(poolAddress);
  const feeGrowthInside = await getFeeGrowthInside(
    poolAddress,
    positionData.tickLower,
    positionData.tickUpper,
    networkName
  );

  const liq = await calculatePositionDataOriginal(
    positionData.token0,
    positionData.token1,
    poolAddress,
    positionData,
    feeGrowthInside,
    networkName,
    true
  );
  return liq;
}
async function calculatePositionDataOriginal(
  token0Address,
  token1Address,
  poolAddress,
  positionData,
  feeGrowthInside,
  networkName,
  returnBigInt = false
) {
  try {
    const provider = getProvider(networkName);
    const token0 = await getToken(token0Address, networkName);
    const token1 = await getToken(token1Address, networkName);
    const liquidity = JSBI.BigInt(positionData.liquidity.toString());

    const deltaFeeGrowth0 = JSBI.subtract(
      JSBI.BigInt(feeGrowthInside.feeGrowthInside0X128.toString()),
      JSBI.BigInt(positionData.feeGrowthInside0LastX128.toString())
    );
    const deltaFeeGrowth1 = JSBI.subtract(
      JSBI.BigInt(feeGrowthInside.feeGrowthInside1X128.toString()),
      JSBI.BigInt(positionData.feeGrowthInside1LastX128.toString())
    );

    const denominator = JSBI.exponentiate(JSBI.BigInt(2), JSBI.BigInt(128));
    const feesEarnedToken0 = JSBI.divide(JSBI.multiply(deltaFeeGrowth0, liquidity), denominator);
    const feesEarnedToken1 = JSBI.divide(JSBI.multiply(deltaFeeGrowth1, liquidity), denominator);

    const slot0 = await getSlotData(poolAddress, provider);
    const poolLiquidity = await getLiquidity(poolAddress, provider);

    const poolInstance = new Pool(
      token0,
      token1,
      Number(positionData.fee),
      JSBI.BigInt(slot0.sqrtPriceX96.toString()),
      JSBI.BigInt(poolLiquidity.toString()),
      Number(slot0.tick)
    );

    const position = new Position({
      pool: poolInstance,
      liquidity: liquidity,
      tickLower: Number(positionData.tickLower),
      tickUpper: Number(positionData.tickUpper),
    });
    if (returnBigInt) {
      return {
        liquidityAmountToken0: position.amount0.toString(),
        liquidityAmountToken1: position.amount1.toString(),
        uncollectedFeeToken0: feesEarnedToken0,
        uncollectedFeeToken1: feesEarnedToken1,
      };
    } else {
      return {
        liquidityAmountToken0: parseFloat(position.amount0.toSignificant(6)),
        liquidityAmountToken1: parseFloat(position.amount1.toSignificant(6)),
        uncollectedFeeToken0: parseFloat(formatUnits(feesEarnedToken0.toString(), token0.decimals)),
        uncollectedFeeToken1: parseFloat(formatUnits(feesEarnedToken1.toString(), token1.decimals)),
      };
    }
  } catch (error) {
    console.error("Error calculating position data:", error);
    throw error;
  }
}
async function getPosition(networkName, positionId) {
  const networkConfig = NETWORK_CONFIG[networkName];
  const provider = getProvider(networkName);
  const positionManager = new Contract(
    networkConfig.nonfungiblePositionManagerAddress,
    artifacts.NonfungiblePositionManager.abi,
    provider
  );
  const rawPosition = await positionManager.positions(positionId);
  const position = {
    nonce: rawPosition[0],
    operator: rawPosition[1],
    token0: rawPosition[2],
    token1: rawPosition[3],
    fee: rawPosition[4],
    tickLower: rawPosition[5],
    tickUpper: rawPosition[6],
    liquidity: rawPosition[7],
    feeGrowthInside0LastX128: rawPosition[8],
    feeGrowthInside1LastX128: rawPosition[9],
    tokensOwed0: rawPosition[10],
    tokensOwed1: rawPosition[11],
  };
  return position;
}
async function getPoolAddress(networkName, token0, token1, fee) {
  const networkConfig = NETWORK_CONFIG[networkName];
  const provider = getProvider(networkName);

  const factoryAbi = ["function getPool(address token0, address token1, uint24 fee) external view returns (address)"];
  const factoryContract = new Contract(networkConfig.factoryAddress, factoryAbi, provider);

  let poolAddress = await factoryContract.getPool(token0, token1, fee);

  if (poolAddress === AddressZero) {
    poolAddress = await factoryContract.getPool(token1, token0, fee);
  }
  if (poolAddress === AddressZero) {
    console.log(`No pool found on ${networkName} for tokens ${token1} and ${token0} with fee ${fee}`);
  }
  return poolAddress;
}
async function getFeeGrowthInside(poolAddress, tickLower, tickUpper, networkName) {
  console.log(`Fetching fee growth inside for pool address: ${poolAddress}`);
  const provider = getProvider(networkName);
  const poolContract = new Contract(poolAddress, artifacts.UniswapV3Pool.abi, provider);
  const slot0 = await poolContract.slot0();
  const currentTick = Number(slot0.tick);

  //console.log(`Current tick: ${currentTick}`);

  const [tickLowerData, tickUpperData] = await Promise.all([
    getTickData(poolAddress, tickLower, provider),
    getTickData(poolAddress, tickUpper, provider),
  ]);

  //console.log(`Tick data fetched for lower and upper ticks:`, tickLowerData, tickUpperData);

  const feeGrowthGlobal0X128 = await poolContract.feeGrowthGlobal0X128();
  const feeGrowthGlobal1X128 = await poolContract.feeGrowthGlobal1X128();

  //console.log(`Fee growth global data: 0X128: ${feeGrowthGlobal0X128}, 1X128: ${feeGrowthGlobal1X128}`);

  const feeGrowthInside0X128 = calculateFeeGrowthInside(
    feeGrowthGlobal0X128,
    tickLowerData.feeGrowthOutside0X128,
    tickUpperData.feeGrowthOutside0X128,
    currentTick,
    tickLower,
    tickUpper
  );
  const feeGrowthInside1X128 = calculateFeeGrowthInside(
    feeGrowthGlobal1X128,
    tickLowerData.feeGrowthOutside1X128,
    tickUpperData.feeGrowthOutside1X128,
    currentTick,
    tickLower,
    tickUpper
  );

  //console.log(`Fee growth inside data: 0X128: ${feeGrowthInside0X128}, 1X128: ${feeGrowthInside1X128}`);

  return { feeGrowthInside0X128, feeGrowthInside1X128 };
}
async function getTickData(poolAddress, tick, provider) {
  //console.log(`Fetching tick data for tick: ${tick} at pool address: ${poolAddress}`);
  const poolContract = new Contract(poolAddress, artifacts.UniswapV3Pool.abi, provider);
  const tickData = await poolContract.ticks(tick);

  //console.log(`Tick data retrieved:`, tickData);

  return {
    liquidityGross: tickData.liquidityGross,
    liquidityNet: tickData.liquidityNet,
    feeGrowthOutside0X128: tickData.feeGrowthOutside0X128,
    feeGrowthOutside1X128: tickData.feeGrowthOutside1X128,
    tickCumulativeOutside: tickData.tickCumulativeOutside,
    secondsPerLiquidityOutsideX128: tickData.secondsPerLiquidityOutsideX128,
    secondsOutside: tickData.secondsOutside,
    initialized: tickData.initialized,
  };
}
function calculateFeeGrowthInside(
  feeGrowthGlobal,
  tickLowerOutside,
  tickUpperOutside,
  currentTick,
  tickLower,
  tickUpper
) {
  const feeGrowthBelow = currentTick >= tickLower ? tickLowerOutside : feeGrowthGlobal - tickLowerOutside;
  const feeGrowthAbove = currentTick < tickUpper ? tickUpperOutside : feeGrowthGlobal - tickUpperOutside;
  //console.log(`Fee growth below: ${feeGrowthBelow}, Fee growth above: ${feeGrowthAbove}`);

  const feeGrowthInside = feeGrowthGlobal - feeGrowthBelow - feeGrowthAbove;
  //console.log(`Fee growth inside calculated: ${feeGrowthInside}`);

  return feeGrowthInside;
}

async function getToken(address, networkName) {
  if (TOKEN_MAP[address]) return TOKEN_MAP[address];
  const provider = getProvider(networkName);
  const tokenContract = new Contract(address, artifacts.ERC20.abi, provider);

  const [decimals, symbol, name] = await Promise.all([
    tokenContract.decimals(),
    tokenContract.symbol(),
    tokenContract.name(),
  ]);
  const network = await provider.getNetwork();
  const token = new Token(Number(network.chainId), address, Number(decimals), symbol, name);
  TOKEN_MAP[address] = token;

  return token;
}
async function getSlotData(poolAddress, provider) {
  //console.log(`Fetching slot data for pool: ${poolAddress}`);
  const poolContract = new Contract(poolAddress, artifacts.UniswapV3Pool.abi, provider);
  const slotData = await poolContract.slot0();
  return slotData;
}
async function getLiquidity(poolAddress, provider) {
  //console.log(`Fetching liquidity for pool: ${poolAddress}`);
  const poolContract = new Contract(poolAddress, artifacts.UniswapV3Pool.abi, provider);
  const liquidity = await poolContract.liquidity();
  return liquidity;
}
export async function getEthPriceInDollar() {
  //Hardcoded to USDC/WETH on Mainnet to have a somewhat solid source for the reference price
  const poolData = await fetchPoolData("0x88e6A0c2dDD26FEEb64F039a2c41296FcB3f5640", "Ethereum Mainnet");
  const price = convertSqrtPriceX96ToPrice(poolData.sqrtPriceX96, poolData.token0.decimals, poolData.token1.decimals);
  return 1 / price;
}
export async function getTokenPriceInEth(networkName, tokenAddress) {
  const networkConfig = NETWORK_CONFIG[networkName];
  const WETH = networkConfig.wethAddress;
  return fetchUniswapPrice(networkName, tokenAddress, WETH);
}
export async function fetchUniswapPrice(networkName, token0, token1) {
  const fees = [3000, 10000, 100, 500, 1000];
  let poolAddress;
  let poolData;
  for (let index = 0; index < fees.length; index++) {
    const fee = fees[index];
    poolAddress = await getPoolAddress(networkName, token0, token1, fee);
    if (poolAddress !== ZeroAddress) {
      console.log("Pooladdress: ", poolAddress);
      poolData = await fetchPoolData(poolAddress, networkName);
      if (poolData) {
        if (poolData.liquidity === 0) {
          console.log(`No pool liquidity found at pair ${poolData.token0.symbol}/${poolData.token1.symbol}`);
          continue;
        }
      }
    }
  }
  if (poolAddress) {
    if (poolData) {
      let price = convertSqrtPriceX96ToPrice(poolData.sqrtPriceX96, poolData.token0.decimals, poolData.token1.decimals);
      if (token0 !== poolData.token0.address) {
        price = 1 / price;
      }
      return price;
    }
  }
}
export const getPriceOfOneToken0 = (Decimal0, Decimal1, sqrtPriceX96) => {
  console.log(Decimal0);
  console.log(Decimal1);
  console.log(sqrtPriceX96);
  const buyOneOfToken0 = (Number(sqrtPriceX96) / 2 ** 96) ** 2 / (10 ** Decimal1 / 10 ** Decimal0); //.toFixed(Decimal1);

  return buyOneOfToken0.toString();
};
export const getPriceOfOneToken1 = (Decimal0, Decimal1, sqrtPriceX96) => {
  //console.log("SqrtPriceX96 is", sqrtPriceX96);
  const buyOneOfToken0 = (Number(sqrtPriceX96) / 2 ** 96) ** 2 / (10 ** Decimal1 / 10 ** Decimal0); //.toFixed(Decimal1);
  const buyOneOfToken1 = 1 / buyOneOfToken0; //.toFixed(Decimal0);

  return buyOneOfToken1.toString();
};
function convertSqrtPriceX96ToPrice1(sqrtPriceX96, decimalsToken0, decimalsToken1) {
  const sqrtPrice = parseFloat(sqrtPriceX96) / Math.pow(2, 96); // Scale down sqrtPriceX96
  const priceRatio = Math.pow(sqrtPrice, 2); // Square to get the raw price

  // Adjust for decimals: 10^(decimalsToken1 - decimalsToken0)
  const decimalsAdjustment = Math.pow(10, decimalsToken1 - decimalsToken0);

  // Apply adjustment
  const priceToken0InToken1 = priceRatio * decimalsAdjustment;
  const priceToken1InToken0 = 1 / priceToken0InToken1;

  return priceToken0InToken1;
}

function convertSqrtPriceX96ToPrice(sqrtPriceX96, decimalsToken0, decimalsToken1) {
  const buyOneOfToken0 =
    (sqrtPriceX96 / 2 ** 96) ** 2 / (10 ** decimalsToken1 / 10 ** decimalsToken0).toFixed(decimalsToken1);

  const buyOneOfToken1 = (1 / buyOneOfToken0).toFixed(decimalsToken0);
  return buyOneOfToken0;
}

async function fetchIquoter() {
  //const iQuoter = new Contract(networkConfig.factoryAddress, factoryAbi, provider);
}
async function fetchPoolData(poolAddress, networkName) {
  const provider = getProvider(networkName);
  const poolContract = new Contract(poolAddress, artifacts.UniswapV3Pool.abi, provider);

  const [liquidity, slot0, token0, token1] = await Promise.all([
    poolContract.liquidity(),
    poolContract.slot0(),
    poolContract.token0(),
    poolContract.token1(),
  ]);

  const token0Data = await getToken(token0, networkName);
  const token1Data = await getToken(token1, networkName);

  return {
    liquidity: JSBI.BigInt(liquidity.toString()),
    sqrtPriceX96: JSBI.BigInt(slot0.sqrtPriceX96.toString()),
    //sqrtPriceX96: slot0.sqrtPriceX96,
    currentTick: slot0.tick,
    token0: token0Data,
    token1: token1Data,
  };
}
function calculatePricesAndFees(pool, positionData) {
  const priceLower = TickMath.getSqrtRatioAtTick(positionData.tickLower).toString();
  const priceUpper = TickMath.getSqrtRatioAtTick(positionData.tickUpper).toString();
  const currentPrice = pool.token1Price.toSignificant(6);

  return {
    priceLower,
    priceUpper,
    currentPrice,
  };
}

export async function compoundPool(positionId, transferSystem, uncollectedFeeToken0, uncollectedFeeToken1) {
  const recipient = "0x689640C5d8EFD9776B3f427a68e82BcBE7A0E01b"; // Replace with your recipient address
  const networkConfig = NETWORK_CONFIG[transferSystem];

  const { web3Provider, signer } = await connectMetaMask();

  const calls = [];
  const deadline = Math.floor(Date.now() / 1000) + 3600; // 1 hour from now

  const positionManager = new Contract(
    networkConfig.nonfungiblePositionManagerAddress,
    artifacts.NonfungiblePositionManager.abi,
    signer
  );

  // Step 1: Collect fees for the position

  const collectData = positionManager.interface.encodeFunctionData("collect", [
    {
      tokenId: positionId,
      recipient: recipient,
      amount0Max: uncollectedFeeToken0.toString(),
      amount1Max: uncollectedFeeToken1.toString(),
    },
  ]);
  console.log("Uncollected 0: ", uncollectedFeeToken0.toString())
  console.log("Uncollected 1: ", uncollectedFeeToken1.toString())
  // Step 2: Re-add liquidity for the position
  const adjustedFeeToken0 = JSBI.divide(JSBI.multiply(uncollectedFeeToken0, JSBI.BigInt(80)), JSBI.BigInt(100));
  const adjustedFeeToken1 = JSBI.divide(JSBI.multiply(uncollectedFeeToken1, JSBI.BigInt(80)), JSBI.BigInt(100));
  //const adjustedFeeToken0 = (uncollectedFeeToken0 * JSBI.BigInt(95)) / JSBI.BigInt(100); // 95% of the original value
  //const adjustedFeeToken1 = (uncollectedFeeToken1 * JSBI.BigInt(95)) / JSBI.BigInt(100); // 95% of the original
  console.log("adjustedFeeToken 0: ", adjustedFeeToken0)
  console.log("adjustedFeeToken 1: ", adjustedFeeToken1)
  const increaseLiquidityData = positionManager.interface.encodeFunctionData("increaseLiquidity", [
    {
      tokenId: positionId,
      amount0Desired: uncollectedFeeToken0.toString(), // Example values, replace with actual amounts
      amount1Desired: uncollectedFeeToken1.toString(), // Example values, replace with actual amounts
      amount0Min: 0n, //adjustedFeeToken0.toString(),
      amount1Min: 0n, //adjustedFeeToken1.toString(),
      deadline: deadline,
    },
  ]);
  console.log(2);
  // Add both calls to the batch
  calls.push(collectData, increaseLiquidityData); // ,increaseLiquidityData

  try {
    // Step 3: Execute multicall
    const tx = await positionManager.multicall(calls, { value: 221436472n, gasLimit: 1000000 }); // Adjust gas limit as needed
    console.log("Transaction sent:", tx.hash);

    // Step 4: Wait for transaction receipt
    const receipt = await tx.wait();
    console.log("Transaction mined:", receipt.transactionHash);
  } catch (error) {
    console.error("Error executing multicall:", error);
  }
}

async function connectMetaMask() {
  if (typeof window.ethereum === "undefined") {
    console.error("MetaMask is not installed!");
    throw new Error("MetaMask is required");
  }

  // Initialize BrowserProvider and get signer
  const webProvider = new BrowserProvider(window.ethereum);
  const signer = await webProvider.getSigner(); // Await the signer!

  try {
    const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
    console.log("Connected account:", accounts[0]);

    return { webProvider, signer, account: accounts[0] };
  } catch (error) {
    console.error("User rejected the connection or an error occurred:", error);
    throw error;
  }
}

//UniswapV2

initProviders();

import React, { useState, useEffect } from "react";
import "../styles/Forms.css";
import { saveFormData } from "../scripts/formHandler";
import { db } from "../scripts/firebase";
import { collection, doc, getDocs, onSnapshot, query, updateDoc, where } from "firebase/firestore";

const AddInflowOutflowForm = () => {
  const [formData, setFormData] = useState({
    holder: "",
    direction: "",
    quantity: "",
  });
  const [investments, setInvestments] = useState({});
  const [holders, setHolders] = useState([]); // To store holders from Firebase
  const [isSubmitting, setIsSubmitting] = useState(false); // Loading state
  const [message, setMessage] = useState(""); // Success/error message

  useEffect(() => {
    // Fetch holders from Firebase

    const fetchSymbolsAndInvestments = async () => {
      const investmentSnapshot = await getDocs(collection(db, "Investments"));
      const investmentData = {};
      investmentSnapshot.docs.forEach((doc) => {
        investmentData[doc.data().symbol] = {
          id: doc.id,
          ...doc.data(),
        };
      });
      setInvestments(investmentData);

      const unsubscribe = onSnapshot(collection(db, "Holders"), (snapshot) => {
        const holderNames = snapshot.docs.map((doc) => doc.data().firstName + " " + doc.data().lastName);
        setHolders(holderNames);
      });

      return () => unsubscribe(); // Cleanup on unmount
    };

    fetchSymbolsAndInvestments();
  }, []);

  useEffect(() => { 
    console.log(formData)
    console.log(investments)
   }, [formData,investments]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true); // Indicate submission
    setMessage(""); // Clear previous messages

    try {
      // Add datetime to formData and save to Firebase
      const dataToSave = { ...formData, datetime: new Date().toISOString() };
      await saveFormData("InOutFlows", dataToSave);

      // Update Investments table for CHF
      const investmentSnapshot = await getDocs(
        query(
          collection(db, "Investments"),
          where("symbol", "==", "CHF"),
          where("transferSystem", "==", "Traditional Banking")
        )
      );

      if (!investmentSnapshot.empty) {
        // Get the investment document
        const investmentDoc = investmentSnapshot.docs[0];
        const investmentData = investmentDoc.data();

        // Calculate the new quantity based on direction
        const updatedQuantity =
          formData.direction === "Deposit"
            ? parseFloat(investmentData.quantity) + parseFloat(formData.quantity)
            : parseFloat(investmentData.quantity) - parseFloat(formData.quantity);

        // Update the investment quantity
        await updateDoc(doc(db, "Investments", investmentDoc.id), {
          quantity: updatedQuantity.toFixed(2), // Ensure two decimal places
        });
      } else {
        console.error("CHF entry not found in Investments.");
      }

      setMessage("In/Out Flow added successfully!");

      // Reset form fields
      setFormData({
        holder: "",
        direction: "",
        quantity: "",
      });
    } catch (error) {
      setMessage("Failed to add In/Out Flow. Please try again.");
      console.error("Error saving In/Out Flow:", error);
    } finally {
      setIsSubmitting(false); // Stop loading
    }
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        <h1 className="form-title">Add Inflow or Outflow</h1>
        <div className="form-field">
          <label className="form-label">
            Holder *<span className="form-hint">(Who wants to transfer CHF in or out of the fund?)</span>
          </label>
          <div className="form-radio-group">
            {holders.length > 0 ? (
              holders.map((holder, index) => (
                <label key={index} className="form-radio">
                  <input
                    type="radio"
                    name="holder"
                    value={holder}
                    onChange={handleChange}
                    checked={formData.holder === holder}
                    required
                  />
                  {holder}
                </label>
              ))
            ) : (
              <p>No holders available. Please add one first.</p>
            )}
          </div>
        </div>
        <div className="form-field">
          <label className="form-label">
            Direction *<span className="form-hint">(Deposit or Withdraw)</span>
          </label>
          <div className="form-radio-group">
            <label className="form-radio">
              <input
                type="radio"
                name="direction"
                value="Deposit"
                onChange={handleChange}
                checked={formData.direction === "Deposit"}
                required
              />
              Deposit
            </label>
            <label className="form-radio">
              <input
                type="radio"
                name="direction"
                value="Withdraw"
                onChange={handleChange}
                checked={formData.direction === "Withdraw"}
              />
              Withdraw
            </label>
          </div>
        </div>
        <div className="form-field">
          <label htmlFor="quantity" className="form-label">
            Quantity *
            <span className="form-hint">
              (This must be a quantity in CHF. Max two decimal places, e.g., 1999.99 or 1000)
            </span>
          </label>
          <input
            type="number"
            id="quantity"
            name="quantity"
            value={formData.quantity}
            onChange={handleChange}
            placeholder="Enter the quantity"
            required
            className="form-input"
            step="0.01"
            max={
              formData.direction === "Withdraw" && investments["CHF"]
                ? investments["CHF"].quantity : 1000000000000
                
            }
          />
        </div>
        <button type="submit" className="form-button" disabled={isSubmitting}>
          {isSubmitting ? "Submitting..." : "Submit"}
        </button>
        {message && <p className="form-message">{message}</p>}
      </form>
    </div>
  );
};

export default AddInflowOutflowForm;

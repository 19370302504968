import React, { useState, useEffect } from "react";
import { db } from "../scripts/firebase";
import { collection, getDocs, query, where, doc, updateDoc, addDoc } from "firebase/firestore";
import "../styles/Forms.css";

const RemovePoolLiquidityForm = () => {
  const [formData, setFormData] = useState({
    pair: "",
    token0Removed: "",
    token1Removed: "",
  });
  const [pools, setPools] = useState([]);
  const [investments, setInvestments] = useState({}); // Store investments data

  useEffect(() => {
    // Fetch pools from Firebase
    const fetchPools = async () => {
      const poolSnapshot = await getDocs(collection(db, "Pools"));
      const poolList = poolSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPools(poolList);
    };

    // Fetch investments from Firebase
    const fetchInvestments = async () => {
      const investmentSnapshot = await getDocs(collection(db, "Investments"));
      const investmentData = {};
      investmentSnapshot.docs.forEach((doc) => {
        investmentData[`${doc.data().symbol}_${doc.data().transferSystem}`] = {
          id: doc.id,
          ...doc.data(),
        };
      });
      console.log(investmentData)
      setInvestments(investmentData);
    };

    fetchPools();
    fetchInvestments();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Extract details from the selected pair
      const selectedPool = pools.find((pool) => pool.positionId === formData.pair);
      if (!selectedPool) {
        alert("Pool not found.");
        return;
      }

      const { positionId, token0, token1, fee, token0Liquidity, token1Liquidity, transferSystem } = selectedPool;

      const token0Removed = parseFloat(formData.token0Removed);
      const token1Removed = parseFloat(formData.token1Removed);

      if (token0Removed > parseFloat(token0Liquidity) || token1Removed > parseFloat(token1Liquidity)) {
        alert("Removed quantities cannot exceed current liquidity.");
        return;
      }

      // Add PoolOutflows Entry
      const outflowData = {
        positionId,
        token0,
        token1,
        token0Removed: formData.token0Removed,
        token1Removed: formData.token1Removed,
        fee,
        datetime: new Date().toISOString(),
      };
      await addDoc(collection(db, "PoolOutflows"), outflowData);

      // Update Investments
      // Update token0 investment
      if (investments[`${token0}_${transferSystem}`]) {
        const token0Investment = investments[`${token0}_${transferSystem}`];
        const updatedToken0Quantity = (
          parseFloat(token0Investment.quantity || 0) + token0Removed
        ).toFixed(2);
        await updateDoc(doc(db, "Investments", token0Investment.id), { quantity: updatedToken0Quantity });
      }

      // Update token1 investment
      if (investments[`${token1}_${transferSystem}`]) {
        const token1Investment = investments[`${token1}_${transferSystem}`];
        const updatedToken1Quantity = (
          parseFloat(token1Investment.quantity || 0) + token1Removed
        ).toFixed(2);
        await updateDoc(doc(db, "Investments", token1Investment.id), { quantity: updatedToken1Quantity });
      }

      alert("Liquidity successfully removed and investments updated!");

      // Reset the form
      setFormData({
        pair: "",
        token0Removed: "",
        token1Removed: "",
      });
    } catch (error) {
      console.error("Error removing pool liquidity:", error);
      alert("Failed to process pool liquidity removal. Please try again.");
    }
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        <h1 className="form-title">Remove Pool Liquidity</h1>
        <p className="form-description">
          Account for already removed liquidity. Fill this form if you closed a pool or removed liquidity from one.
        </p>

        {/* Pair */}
        <div className="form-field">
          <label className="form-label">
            Pair *
            <span className="form-hint">(Choose the pool which you removed liquidity from.)</span>
          </label>
          <div className="form-radio-group">
            {pools.map((pool) => (
              <label className="form-radio" key={pool.id}>
                <input
                  type="radio"
                  name="pair"
                  value={pool.positionId}
                  onChange={handleChange}
                  required
                />
                {pool.token0}/{pool.token1} {pool.fee}% {pool.transferSystem} ({pool.positionId})
              </label>
            ))}
          </div>
        </div>

        {/* Token 0 Removed Quantity */}
        <div className="form-field">
          <label htmlFor="token0Removed" className="form-label">
            Token 0 Removed Quantity *{" "}
            {formData.pair &&
              pools.find((pool) => pool.positionId === formData.pair) &&
              `(Max: ${pools.find((pool) => pool.positionId === formData.pair).token0Liquidity || 0})`}
          </label>
          <input
            type="number"
            id="token0Removed"
            name="token0Removed"
            value={formData.token0Removed}
            onChange={handleChange}
            placeholder="Enter the removed quantity for Token 0"
            required
            className="form-input"
            step="0.01"
            max={
              formData.pair &&
              pools.find((pool) => pool.positionId === formData.pair)?.token0Liquidity
            }
          />
        </div>

        {/* Token 1 Removed Quantity */}
        <div className="form-field">
          <label htmlFor="token1Removed" className="form-label">
            Token 1 Removed Quantity *{" "}
            {formData.pair &&
              pools.find((pool) => pool.positionId === formData.pair) &&
              `(Max: ${pools.find((pool) => pool.positionId === formData.pair).token1Liquidity || 0})`}
          </label>
          <input
            type="number"
            id="token1Removed"
            name="token1Removed"
            value={formData.token1Removed}
            onChange={handleChange}
            placeholder="Enter the removed quantity for Token 1"
            required
            className="form-input"
            step="0.01"
            max={
              formData.pair &&
              pools.find((pool) => pool.positionId === formData.pair)?.token1Liquidity
            }
          />
        </div>

        <button type="submit" className="form-button">
          Submit
        </button>
      </form>
    </div>
  );
};

export default RemovePoolLiquidityForm;

import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = ({ inputData, dataType }) => {
  const MIN_BARS = 25;

  // Ensure we have at least MIN_BARS by adding empty placeholders
  const filledData = [...inputData];
  const currentCount = inputData.length;

  if (currentCount < MIN_BARS) {
    for (let i = 0; i < MIN_BARS - currentCount; i++) {
      if (dataType === "pools") {
        filledData.push({
          token0: ``,
          token1: ``,
          token0Liquidity: 0,
          token1Liquidity: 0,
        });
      } else {
        filledData.push({
          symbol: ``,
          quantity: 0,
        });
      }
    }
  }

  // Prepare chart data based on dataType
  let chartData;
  if (dataType === "pools") {
    chartData = {
      labels: filledData.map((item) => (item.token0 ? `${item.token0}/${item.token1}` : "")), // Use token pairs as labels
      datasets: [
        {
          label: "Liquidity-T0",
          data: filledData.map((item) => item.token0Liquidity || 0), // Liquidity for token0
          backgroundColor: "rgba(22, 83, 112, 0.8)", // Color for token0
        },
        {
          label: "Liquidity-T1",
          data: filledData.map((item) => item.token1Liquidity || 0), // Liquidity for token1
          backgroundColor: "rgba(22, 53, 112, 0.8)", // Color for token1
        },
      ],
    };
  } else if(dataType === "positions") {
    // Default behavior for investments/positions
    chartData = {
      labels: filledData.map((item) => item.symbol), // Use symbols as labels
      datasets: [
        {
          label: "Value [$]",
          data: filledData.map((item) => item.dollarValue), // Use the total quantity
          backgroundColor: "rgba(22, 83, 112, 0.8)", // Bar color
        },
      ],
    };
  }else {
    // Default behavior for investments/positions
    chartData = {
      labels: filledData.map((item) => item.symbol), // Use symbols as labels
      datasets: [
        {
          label: "Value [$]",
          data: filledData.map((item) => item.dollarValue), // Use the total quantity
          backgroundColor: "rgba(22, 83, 112, 0.8)", // Bar color
        },
      ],
    };
  }

  // Chart Options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true, // Show legend for pools
      },
      title: {
        display: true,
        text: dataType === "pools" ? "Pool Liquidity" : "Portfolio Data",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: dataType === "pools" ? "Token Pairs" : "Symbols",
        },
        grid: {
          display: false, // Make x-axis grid lines invisible
        },
        ticks: {
          color: "grey", // Make x-axis labels grey
        },
        border: {
          color: "grey", // Make x-axis line grey
        },
      },
      y: {
        title: {
          display: true,
          text: "Quantity",
        },
        grid: {
          display: false, // Make y-axis grid lines invisible
        },
        ticks: {
          color: "grey", // Make y-axis labels grey
        },
        border: {
          color: "grey", // Make y-axis line grey
        },
      },
    },
  };

  // Render Chart
  return (
    <div style={{ width: "100%", height: "400px" }}>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default BarChart;

import React, { useState } from "react";
import "../styles/DynamicTables.css";
import AddInflowOutflowForm from "./AddInflowOutflowForm";
import AddTradeForm from "./AddTradeForm";
import NewHolderForm from "./NewHolderForm";
import AddTransferSystemForm from "./AddTransferSystemForm";
import NewSymbolForm from "./NewSymbolForm";
import NewPoolLiquidityForm from "./NewPoolLiquidityForm";
import RemovePoolLiquidityForm from "./RemovePoolLiquidityForm";
import ClaimPoolFeeForm from "./ClaimPoolFeeForm";
import DynamicTables from "./DynamicTables";

const ClientView = ({ user, onLogout }) => {
  const [activeTab, setActiveTab] = useState("Dashboard");

  const tabs = [
    { name: "Dashboard", title: "Total Summary", columns: ["Category", "Amount", "Date"] },
    {
      name: "Totals",
      title: "Positions Overview",
      columns: ["Symbol", "Transfer System", "Position Size", "Dollar Value"],
    },
    {
      name: "Pools",
      title: "Pools Overview",
      columns: [
        "Details (Pair and Position ID)",
        "Current Token 0 Liquidity",
        "Current Token 1 Liquidity",
        "Uncollected Fees (Token 0)",
        "Uncollected Fees (Token 1)",
        "Fetch Liquidity",
        "Compound",
      ],
    },
    {
      name: "Investments",
      title: "Investments Overview",
      columns: ["Symbol", "Transfer System", "Quantity", "Value in Dollar"],
    },
    { name: "TransferSystems", title: "Transfer Systems", columns: ["System Name", "Symbol"] },
    {
      name: "Symbols",
      title: "Symbols List",
      columns: ["Symbol", "Full Name", "Transfer System", "Price Source", "Address", "PriceInDollar"],
    },
    { name: "Holders", title: "Holders List", columns: ["First Name", "Last Name", "Share"] },
    { name: "InOutFlows", title: "In/Out Flows", columns: ["Holder Name", "Direction", "Amount", "Date"] },
    {
      name: "Trades",
      title: "Trades Overview",
      columns: ["Input Asset", "Output Asset", "Input Quantity", "Output Quantity", "Datetime"],
    },
    {
      name: "PoolInflows",
      title: "Pool Inflows",
      columns: ["Position ID", "Token 0", "Token 1", "Token 0 Added", "Token 1 Added", "Datetime"],
    },
    {
      name: "PoolOutflows",
      title: "Pool Outflows",
      columns: ["Position ID", "Token 0", "Token 1", "Token 0 Removed", "Token 1 Removed", "Date"],
    },
    {
      name: "PoolFeeClaims",
      title: "Pool Fee Claims",
      columns: ["Pool ID", "Token 0 Claimed", "Token 1 Claimed", "Date"],
    },
    {
      name: "Extras",
      title: "Extras",
      columns: [],
    },
  ];

  const renderForm = () => {
    switch (activeTab) {
      case "Holders":
        return <NewHolderForm />;
      case "InOutFlows":
        return <AddInflowOutflowForm />;
      case "Trades":
        return <AddTradeForm />;
      case "TransferSystems":
        return <AddTransferSystemForm />;
      case "Symbols":
        return <NewSymbolForm />;
      case "PoolInflows":
        return <NewPoolLiquidityForm />;
      case "PoolOutflows":
        return <RemovePoolLiquidityForm />;
      case "PoolFeeClaims":
        return <ClaimPoolFeeForm />;
      default:
        return null; // No form for Investments, Positions, Totals, Pools
    }
  };

  return (
    <div className="background-full">
      <div className="client-view">
        <div className="button-container">
          {tabs.map((tab) => (
            <button
              key={tab.name}
              className={`tab-button ${activeTab === tab.name ? "active" : ""}`}
              onClick={() => setActiveTab(tab.name)}
            >
              {tab.name}
            </button>
          ))}
          <div>
            <button onClick={onLogout} className="logoutButton tab-button">
              Logout
            </button>
          </div>
        </div>
        <div className="flexBox">
          <DynamicTables activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} />
          <div className={renderForm() === null ? "form-container-outter-empty" : "form-container-outter"}>
            {renderForm()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientView;

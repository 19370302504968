import React, { useState, useEffect } from "react";
import "../styles/Forms.css";
import { saveFormData } from "../scripts/formHandler"; // Import the saveFormData function
import { db } from "../scripts/firebase";
import { collection, getDocs, onSnapshot, query, where } from "firebase/firestore";

const NewSymbolForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    symbol: "",
    transferSystem: "",
    priceSource: "",
    tokenAddress: "", // New field for token address
  });
  const [transferSystems, setTransferSystems] = useState([]); // List of transfer systems
  const [isSubmitting, setIsSubmitting] = useState(false); // For loading state
  const [message, setMessage] = useState(""); // For success/error message

  const pricingOptions = ["Kraken", "Uniswap", "Raydium", "None"]; // Fixed pricing options

  useEffect(() => {
    // Fetch transfer systems from Firebase
    const unsubscribe = onSnapshot(collection(db, "TransferSystems"), (snapshot) => {
      const systems = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTransferSystems(systems);
    });

    return () => unsubscribe(); // Cleanup on unmount
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true); // Indicate form is being submitted
    setMessage(""); // Clear any previous messages

    try {
      // Save the form data to the "Symbols" collection
      await saveFormData("Symbols", formData);

      // Check if the symbol already exists in Investments
      const investmentSnapshot = await getDocs(
        query(
          collection(db, "Investments"),
          where("symbol", "==", formData.symbol),
          where("transferSystem", "==", formData.transferSystem)
        )
      );

      // Add the symbol to Investments if it doesn't exist
      if (investmentSnapshot.empty) {
        await saveFormData("Investments", {
          symbol: formData.symbol,
          transferSystem: formData.transferSystem,
          quantity: 0,
          price: 0,
          value: 0,
        });
      }

      setMessage("Symbol added successfully!");
      setFormData({
        name: "",
        symbol: "",
        transferSystem: "",
        priceSource: "",
        tokenAddress: "",
      }); // Reset form fields
    } catch (error) {
      setMessage("Failed to add symbol. Please try again.");
      console.error("Error saving symbol data:", error);
    } finally {
      setIsSubmitting(false); // Stop the loading state
    }
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        <h1 className="form-title">New Symbol</h1>
        <div className="form-field">
          <label htmlFor="name" className="form-label">
            Name *
            <span className="form-hint">(Full Name of the new asset, e.g., Bitcoin)</span>
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter the full name"
            required
            className="form-input"
          />
        </div>
        <div className="form-field">
          <label htmlFor="symbol" className="form-label">
            Symbol *
            <span className="form-hint">(Symbol of the new asset, e.g., BTC)</span>
          </label>
          <input
            type="text"
            id="symbol"
            name="symbol"
            value={formData.symbol}
            onChange={handleChange}
            placeholder="Enter the symbol"
            required
            className="form-input"
          />
        </div>
        <div className="form-field">
          <label className="form-label">
            Transfer System *
            <span className="form-hint">(Choose the transfer system for the new asset)</span>
          </label>
          <div className="form-radio-group">
            {transferSystems.length > 0 ? (
              transferSystems.map((system) => (
                <label key={system.id} className="form-radio">
                  <input
                    type="radio"
                    name="transferSystem"
                    value={system.name}
                    onChange={handleChange}
                    required
                  />
                  {system.name}
                </label>
              ))
            ) : (
              <p>No transfer systems available. Please add one first.</p>
            )}
          </div>
        </div>
        <div className="form-field">
          <label className="form-label">
            Pricing Option *
            <span className="form-hint">
              (Choose where the system can fetch USD prices for this symbol. Choose 'none' to exclude this symbol from
              valuations.)
            </span>
          </label>
          <div className="form-radio-group">
            {pricingOptions.map((option, index) => (
              <label key={index} className="form-radio">
                <input
                  type="radio"
                  name="priceSource"
                  value={option}
                  onChange={handleChange}
                  required
                />
                {option}
              </label>
            ))}
          </div>
        </div>
        {(formData.priceSource === "Uniswap" || formData.priceSource === "Raydium") && (
          <div className="form-field">
            <label htmlFor="tokenAddress" className="form-label">
              Token Address *
              <span className="form-hint">(Enter the contract address of the token)</span>
            </label>
            <input
              type="text"
              id="tokenAddress"
              name="tokenAddress"
              value={formData.tokenAddress}
              onChange={handleChange}
              placeholder="Enter the token address"
              required
              className="form-input"
            />
          </div>
        )}
        <button type="submit" className="form-button" disabled={isSubmitting}>
          {isSubmitting ? "Submitting..." : "Submit"}
        </button>
        {message && <p className="form-message">{message}</p>}
      </form>
    </div>
  );
};

export default NewSymbolForm;

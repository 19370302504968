export const NETWORK_CONFIG = {
  "Ethereum Arbitrum": {
    networkName: "Ethereum Arbitrum",
    rpcUrl: "https://arb-mainnet.g.alchemy.com/v2/lbT0FGqeH8vkg1DEOSVKSBWE2JZRB2Pq",
    nonfungiblePositionManagerAddress: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
    chainId: 42161,
    factoryAddress: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
    wethAddress: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1"
  },
  "Ethereum Base": {
    networkName: "Ethereum Base",
    rpcUrl: "https://base-mainnet.g.alchemy.com/v2/lbT0FGqeH8vkg1DEOSVKSBWE2JZRB2Pq",
    nonfungiblePositionManagerAddress: "0x03a520b32C04BF3bEEf7BEb72E919cf822Ed34f1",
    chainId: 8453,
    factoryAddress: "0x33128a8fC17869897dcE68Ed026d694621f6FDfD",
    wethAddress: "0x4200000000000000000000000000000000000006"
  },
  "Ethereum Mainnet": {
    networkName: "Ethereum Mainnet",
    rpcUrl: "https://eth-mainnet.g.alchemy.com/v2/lbT0FGqeH8vkg1DEOSVKSBWE2JZRB2Pq",
    nonfungiblePositionManagerAddress: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
    chainId: 1,
    factoryAddress: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
    wethAddress: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"
  },
};

import React, { useState, useEffect } from "react";
import { db } from "../scripts/firebase";
import { collection, addDoc, getDocs, query, where, doc, updateDoc } from "firebase/firestore";
import "../styles/Forms.css";

const NewPoolLiquidityForm = () => {
  const [formData, setFormData] = useState({
    existingLiquidity: "",
    positionId: "",
    token0: "",
    token1: "",
    fee: "",
    token0Added: "",
    token1Added: "",
  });
  const [symbols, setSymbols] = useState([]);
  const [pools, setPools] = useState([]);
  const [investments, setInvestments] = useState({}); // Store investments data

  useEffect(() => {
    // Fetch symbols from Firebase
    const fetchSymbols = async () => {
      const symbolSnapshot = await getDocs(collection(db, "Symbols"));
      const symbolList = symbolSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSymbols(symbolList);
    };

    // Fetch pools from Firebase
    const fetchPools = async () => {
      const poolSnapshot = await getDocs(collection(db, "Pools"));
      const poolList = poolSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPools(poolList);
    };

    // Fetch investments from Firebase
    const fetchInvestments = async () => {
      const investmentSnapshot = await getDocs(collection(db, "Investments"));
      const investmentData = {};
      investmentSnapshot.docs.forEach((doc) => {
        investmentData[`${doc.data().symbol}_${doc.data().transferSystem}`] = {
          id: doc.id,
          ...doc.data(),
        };
      });
      setInvestments(investmentData);
    };

    fetchSymbols();
    fetchPools();
    fetchInvestments();
  }, []);
  useEffect(() => {
    console.log(formData);
  }, [formData]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name);
    console.log(value);
    if (name === "token0" || name === "token1") {
      setFormData((prev) => ({
        ...prev,
        [name]: value.split("_")[0],
        transferSystem: value.split("_")[1],
      }));
      return;
    }
    if (name === "pair") {
      console.log(formData);
      console.log(pools);
      const existingPool = pools.find((x) => x.positionId === value);
      setFormData((prev) => ({
        ...prev,
        [name]: value,
        token0: existingPool.token0,
        token1: existingPool.token1,
        fee: existingPool.fee,
        transferSystem: existingPool.transferSystem,
        positionId: existingPool.positionId,
      }));
      return;
    }
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Extract details from the selected pair for existing pools
      let token0, token1, fee, transferSystem, positionId;

      if (formData.existingLiquidity === "Yes") {
        const existingPool = pools.find((x) => x.positionId === formData.pair);
        if (!existingPool) {
          throw new Error("Selected pool not found");
        }

        token0 = existingPool.token0;
        token1 = existingPool.token1;
        fee = existingPool.fee;
        transferSystem = existingPool.transferSystem;
        positionId = existingPool.positionId;

        // Add PoolInflows Entry
        const inflowData = {
          positionId,
          token0,
          token1,
          token0Added: formData.token0Added,
          token1Added: formData.token1Added,
          fee,
          datetime: new Date().toISOString(),
        };
        await addDoc(collection(db, "PoolInflows"), inflowData);

        // Update Investments
        // Update token0 investment
        if (investments[`${token0}_${transferSystem}`]) {
          const token0Investment = investments[`${token0}_${transferSystem}`];
          const updatedToken0Quantity = (
            parseFloat(token0Investment.quantity || 0) - parseFloat(formData.token0Added)
          ).toFixed(2);
          await updateDoc(doc(db, "Investments", token0Investment.id), { quantity: updatedToken0Quantity });
        }

        // Update token1 investment
        if (investments[`${token1}_${transferSystem}`]) {
          const token1Investment = investments[`${token1}_${transferSystem}`];
          const updatedToken1Quantity = (
            parseFloat(token1Investment.quantity || 0) - parseFloat(formData.token1Added)
          ).toFixed(2);
          await updateDoc(doc(db, "Investments", token1Investment.id), { quantity: updatedToken1Quantity });
        }

        alert("Liquidity added to existing pool!");
      } else {
        // New Pool Logic
        const newPoolData = {
          positionId: formData.positionId,
          token0: formData.token0,
          token1: formData.token1,
          fee: formData.fee,
          transferSystem: formData.transferSystem, // Adjust if necessary
          token0Liquidity: formData.token0Added,
          token1Liquidity: formData.token1Added,
          token0UncollectedFees: "0", // Initialize uncollected fees for Token 0
          token1UncollectedFees: "0", // Initialize uncollected fees for Token 1
        };

        await addDoc(collection(db, "Pools"), newPoolData);

        const inflowData = {
          positionId: formData.positionId,
          token0: formData.token0,
          token1: formData.token1,
          token0Added: formData.token0Added,
          token1Added: formData.token1Added,
          fee: formData.fee,
          datetime: new Date().toISOString(),
        };

        await addDoc(collection(db, "PoolInflows"), inflowData);

        
        // Update Investments
        // Update token0 investment
        if (investments[`${formData.token0}_${formData.transferSystem}`]) {
            const token0Investment = investments[`${formData.token0}_${formData.transferSystem}`];
            const updatedToken0Quantity = (
              parseFloat(token0Investment.quantity || 0) - parseFloat(formData.token0Added)
            ).toFixed(2);
            await updateDoc(doc(db, "Investments", token0Investment.id), { quantity: updatedToken0Quantity });
          }
  
          // Update token1 investment
          if (investments[`${formData.token1}_${formData.transferSystem}`]) {
            const token1Investment = investments[`${formData.token1}_${formData.transferSystem}`];
            const updatedToken1Quantity = (
              parseFloat(token1Investment.quantity || 0) - parseFloat(formData.token1Added)
            ).toFixed(2);
            await updateDoc(doc(db, "Investments", token1Investment.id), { quantity: updatedToken1Quantity });
          }

        alert("New pool and liquidity added!");
      }

      // Reset the form
      setFormData({
        existingLiquidity: "",
        positionId: "",
        token0: "",
        token1: "",
        fee: "",
        pair: "",
        token0Added: "",
        token1Added: "",
      });
    } catch (error) {
      console.error("Error processing pool liquidity:", error);
      alert("Failed to process pool liquidity. Please try again.");
    }
  };

  const fees = ["0.01", "0.05", "0.1", "0.3", "1"];

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        <h1 className="form-title">New Pool Liquidity</h1>
        <p className="form-description">
          Account for already added liquidity. Fill this form if you opened a new pool or added liquidity to an existing
          one.
        </p>

        <div className="form-field">
          <label className="form-label">Existing Liquidity *</label>
          <div className="form-radio-group">
            <label className="form-radio">
              <input type="radio" name="existingLiquidity" value="Yes" onChange={handleChange} required />
              Yes
            </label>
            <label className="form-radio">
              <input type="radio" name="existingLiquidity" value="No" onChange={handleChange} required />
              No
            </label>
          </div>
        </div>

        {formData.existingLiquidity === "No" && (
          <>
            <div className="form-field">
              <label htmlFor="positionId" className="form-label">
                Position ID *
              </label>
              <input
                type="number"
                id="positionId"
                name="positionId"
                value={formData.positionId}
                onChange={handleChange}
                placeholder="Enter the Position ID"
                required
                className="form-input"
              />
            </div>
            <div className="form-field">
              <label className="form-label">Token 0 *</label>
              <div className="form-radio-group">
                {symbols.map((symbol) => (
                  <label className="form-radio" key={symbol.id}>
                    <input
                      type="radio"
                      name="token0"
                      value={`${symbol.symbol}_${symbol.transferSystem}`}
                      onChange={handleChange}
                      required
                    />
                    {symbol.symbol} ({symbol.transferSystem}) - Available:{" "}
                    {investments[`${symbol.symbol}_${symbol.transferSystem}`]?.quantity || 0}
                  </label>
                ))}
              </div>
            </div>
            <div className="form-field">
              <label className="form-label">Token 1 *</label>
              <div className="form-radio-group">
                {symbols.map((symbol) => (
                  <label className="form-radio" key={symbol.id}>
                    <input
                      type="radio"
                      name="token1"
                      value={`${symbol.symbol}_${symbol.transferSystem}`}
                      onChange={handleChange}
                      required
                    />
                    {symbol.symbol} ({symbol.transferSystem}) - Available:{" "}
                    {investments[`${symbol.symbol}_${symbol.transferSystem}`]?.quantity || 0}
                  </label>
                ))}
              </div>
            </div>
            <div className="form-field">
              <label className="form-label">Fee *</label>
              <div className="form-radio-group">
                {fees.map((fee, index) => (
                  <label className="form-radio" key={index}>
                    <input type="radio" name="fee" value={fee} onChange={handleChange} required />
                    {fee}%
                  </label>
                ))}
              </div>
            </div>
          </>
        )}

        {formData.existingLiquidity === "Yes" && (
          <div className="form-field">
            <label className="form-label">Choose Existing Pool *</label>
            <div className="form-radio-group">
              {pools.map((pool) => (
                <label className="form-radio" key={pool.id}>
                  <input type="radio" name="pair" value={pool.positionId} onChange={handleChange} required />
                  {pool.token0}/{pool.token1} {pool.fee}% {pool.transferSystem} ({pool.positionId})
                </label>
              ))}
            </div>
          </div>
        )}

        <div className="form-field">
          <label htmlFor="token0Added" className="form-label">
            Token 0 Added Liquidity *
          </label>
          <input
            type="number"
            id="token0Added"
            name="token0Added"
            value={formData.token0Added}
            onChange={handleChange}
            placeholder="Enter the amount for Token 0"
            required
            className="form-input"
            max={investments[`${formData.token0}_${formData.transferSystem}`]?.quantity || 0}
            step="0.01"
          />
        </div>
        <div className="form-field">
          <label htmlFor="token1Added" className="form-label">
            Token 1 Added Liquidity *
          </label>
          <input
            type="number"
            id="token1Added"
            name="token1Added"
            value={formData.token1Added}
            onChange={handleChange}
            placeholder="Enter the amount for Token 1"
            required
            className="form-input"
            max={investments[`${formData.token1}_${formData.transferSystem}`]?.quantity || 0}
            step="0.01"
          />
        </div>

        <button type="submit" className="form-button">
          Submit
        </button>
      </form>
    </div>
  );
};

export default NewPoolLiquidityForm;

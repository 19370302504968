import React, { useEffect, useState } from "react";
import "../styles/Forms.css";
import { db } from "../scripts/firebase";
import { collection, getDocs, addDoc, query, where, updateDoc, doc } from "firebase/firestore";

const AddTradeForm = () => {
  const [formData, setFormData] = useState({
    inputCurrency: "",
    inputQuantity: "",
    outputCurrency: "",
    outputQuantity: "",
  });

  const [symbols, setSymbols] = useState([]);
  const [investments, setInvestments] = useState({});
  const [message, setMessage] = useState(""); // For success/error messages
  const [isSubmitting, setIsSubmitting] = useState(false); // To show loading state

  // Fetch symbols and investments from Firebase
  useEffect(() => {
    const fetchSymbolsAndInvestments = async () => {
      const snapshot = await getDocs(collection(db, "Symbols"));
      const symbolData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSymbols(symbolData);

      const investmentSnapshot = await getDocs(collection(db, "Investments"));
      const investmentData = {};
      investmentSnapshot.docs.forEach((doc) => {
        investmentData[doc.data().symbol] = {
          id: doc.id,
          ...doc.data(),
        };
      });
      setInvestments(investmentData);
    };

    fetchSymbolsAndInvestments();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setMessage("");

    try {
      // Validate the input quantity range
      const inputCurrencyInvestment = investments[formData.inputCurrency];
      if (parseFloat(formData.inputQuantity) > parseFloat(inputCurrencyInvestment.quantity)) {
        setMessage("Error: Input quantity exceeds the available investment.");
        setIsSubmitting(false);
        return;
      }

      // Add trade data to the "Trades" collection in Firebase
      await addDoc(collection(db, "Trades"), {
        ...formData,
        datetime: new Date().toISOString(), // Add datetime field
      });

      // Update Investments table
      const inputInvestment = investments[formData.inputCurrency];
      const outputInvestment = investments[formData.outputCurrency];

      // Update the input currency (reduce quantity)
      if (inputInvestment) {
        await updateDoc(doc(db, "Investments", inputInvestment.id), {
          quantity: (
            parseFloat(inputInvestment.quantity) - parseFloat(formData.inputQuantity)
          ).toFixed(2),
        });
      }

      // Update the output currency (increase quantity)
      if (outputInvestment) {
        await updateDoc(doc(db, "Investments", outputInvestment.id), {
          quantity: (
            parseFloat(outputInvestment.quantity) + parseFloat(formData.outputQuantity)
          ).toFixed(2),
        });
      } else {
        // If output currency doesn't exist in Investments, create a new entry
        await addDoc(collection(db, "Investments"), {
          symbol: formData.outputCurrency,
          transferSystem: symbols.find((sym) => sym.symbol === formData.outputCurrency)?.transferSystem || "Unknown",
          quantity: parseFloat(formData.outputQuantity).toFixed(2),
          price: 0, // Initial price can be set to 0, to be updated later
          value: 0, // Value calculation can be added later
        });
      }

      setMessage("Trade added successfully!");
      setFormData({
        inputCurrency: "",
        inputQuantity: "",
        outputCurrency: "",
        outputQuantity: "",
      }); // Reset form fields
    } catch (error) {
      console.error("Error adding trade:", error);
      setMessage("Failed to add trade. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        <h1 className="form-title">Add Trade</h1>
        <p className="form-description">
          This will add a trade to the trades sheet and update the Position Sizes in the Investments sheet. <br />
          <strong>Hint:</strong> Do not spend what you don’t have.
        </p>

        {/* Input Currency */}
        <div className="form-field">
          <label className="form-label">Input Currency *</label>
          <div className="form-radio-group">
            {symbols.map((symbol) => (
              <label className="form-radio" key={symbol.id}>
                <input
                  type="radio"
                  name="inputCurrency"
                  value={symbol.symbol}
                  onChange={handleChange}
                  required
                />
                {symbol.symbol} ({symbol.transferSystem}) - Available:{" "}
                {investments[symbol.symbol]?.quantity || 0}
              </label>
            ))}
          </div>
        </div>

        {/* Input Quantity */}
        <div className="form-field">
          <label htmlFor="inputQuantity" className="form-label">
            Input Quantity *
          </label>
          <input
            type="number"
            id="inputQuantity"
            name="inputQuantity"
            value={formData.inputQuantity}
            onChange={handleChange}
            placeholder="Enter the input quantity"
            required
            className="form-input"
            step="0.01"
            max={
              formData.inputCurrency && investments[formData.inputCurrency]
                ? investments[formData.inputCurrency].quantity
                : 0
            }
          />
        </div>

        {/* Output Currency */}
        <div className="form-field">
          <label className="form-label">Output Currency *</label>
          <div className="form-radio-group">
            {symbols.map((symbol) => (
              <label className="form-radio" key={symbol.id}>
                <input
                  type="radio"
                  name="outputCurrency"
                  value={symbol.symbol}
                  onChange={handleChange}
                  required
                />
                {symbol.symbol} ({symbol.transferSystem})
              </label>
            ))}
          </div>
        </div>

        {/* Output Quantity */}
        <div className="form-field">
          <label htmlFor="outputQuantity" className="form-label">
            Output Quantity *
          </label>
          <input
            type="number"
            id="outputQuantity"
            name="outputQuantity"
            value={formData.outputQuantity}
            onChange={handleChange}
            placeholder="Enter the output quantity"
            required
            className="form-input"
            step="0.01"
          />
        </div>

        <button type="submit" className="form-button" disabled={isSubmitting}>
          {isSubmitting ? "Submitting..." : "Submit"}
        </button>
        {message && <p className="form-message">{message}</p>}
      </form>
    </div>
  );
};

export default AddTradeForm;

import React, { useState } from "react";
import "../styles/Forms.css";
import { saveFormData } from "../scripts/formHandler"; // Import the saveFormData function

const NewHolderForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false); // For loading state
  const [message, setMessage] = useState(""); // For success/error message

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true); // Indicate form is being submitted
    setMessage(""); // Clear any previous messages

    try {
      // Save the form data to the "Holders" collection
      await saveFormData("Holders", formData);
      setMessage("Form submitted successfully!");
      setFormData({ firstName: "", lastName: "" }); // Reset form fields
    } catch (error) {
      setMessage("Failed to submit the form. Please try again.");
      console.error("Error saving form data:", error);
    } finally {
      setIsSubmitting(false); // Stop the loading state
    }
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        <h1 className="form-title">New Holder</h1>
        <div className="form-field">
          <label htmlFor="firstName" className="form-label">
            Firstname *
          </label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            placeholder="Enter your first name"
            required
            className="form-input"
          />
        </div>
        <div className="form-field">
          <label htmlFor="lastName" className="form-label">
            Lastname *
          </label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            placeholder="Enter your last name"
            required
            className="form-input"
          />
        </div>
        <button type="submit" className="form-button" disabled={isSubmitting}>
          {isSubmitting ? "Submitting..." : "Submit"}
        </button>
        {message && <p className="form-message">{message}</p>}
      </form>
    </div>
  );
};

export default NewHolderForm;

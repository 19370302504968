import React, { useState, useEffect } from "react";
import Login from "./components/Login";
import ClientView from "./components/ClientView";
import { auth } from "./scripts/firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";

const App = () => {
  const [user, setUser] = useState(null);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [loading, setLoading] = useState(true);

  const authorizedEmails = [
    "cedric.hirschi@gmail.com", // Replace with authorized emails
    "mauro.r.miotti@gmail.com",
  ];

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        const email = currentUser.email;
        setUser(currentUser);
        setIsAuthorized(authorizedEmails.includes(email));
      } else {
        setUser(null);
        setIsAuthorized(false);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      console.log("User signed out successfully");
      setUser(null);
      setIsAuthorized(false); // Clear user and authorization state
    } catch (error) {
      console.error("Error signing out:", error.message);
    }
  };

  if (loading) {
    return <div>Loading...</div>; // Show loading while checking auth
  }

  if (!user) {
    // Show login screen if no user is logged in
    return <Login />;
  }

  if (!isAuthorized) {
    // Show access denied screen with option to switch accounts
    return (
      <div className="login">
        <div className="loginBox">
          <h1>Portfolio Manager</h1>
          <div>
            <div>Access Denied: You are not authorized to use this app.</div>
            <br></br>

            <button onClick={handleLogout} className="loginButton">
              Switch Account
            </button>
          </div>
          <br></br>
          <br></br>
        </div>
      </div>
    );
  }

  return (
    <div>
      {user && (
        <div>
          <ClientView user={user} onLogout={handleLogout} />
        </div>
      )}
    </div>
  );
};



export default App;
